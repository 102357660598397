.App {
  text-align: center;
  background-color: #10233B;
  color: #F6F6F6;

}

.terms-privacy-container {
  padding: 0 20px 20px 20px;
  text-align: left;
}

a {
  text-decoration: none;
  color: #496CBA;
}

a:hover {
  text-decoration: underline;
}

#recaptcha-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 320px;
}

.ratingError, .captchaError, .termsError {
  margin: 0;
  font-size: 13px;
  color: #f44336;
}

.captchaError {
  padding-bottom: 10px;
  padding-top: 2.5px;
}

.privacyText {
  font-size: 12px;
  width: 225px;
  margin-bottom: 0px;
  z-index: 1;
}

.termsError {
  padding-bottom: 10px;
  margin-top: -5px;
}

.terms-container {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 23px 0px 10px;
  width: 280px;
  position: relative;
  z-index: 1;
}

.ratingAst, .captchaAst, .termsAst {
  position: absolute;
  font-size: 18px;
  color: #f44336;
}

.ratingAst {
  top: -5px;
}

.captchaAst {
  right: 0;
  top: -15px;
}

.termsAst {
  top: 5px;
  right: 17px;
}

#reviewForm {
  transition: opacity .5s ease, transform 1s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formTitle {
  font-size: calc(14px + 2vmin);
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 25px;
}

.formField {
  position: relative;
}

button {
  margin-top: 15px !important;
  background-color: #BDC8E2 !important;
  color: #10233B !important;
}

.MuiCheckbox-root {
  color: #BDC8E2 !important;
  padding-top: 10px !important;
}

.PrivateSwitchBase-input-5:hover {
  cursor: pointer;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #BDC8E2 !important;
}

.MuiFormLabel-root {
  color: #bdc8e2d5 !important;
}

.MuiInput-input::placeholder, .MuiInput-input {
  color: #F6F6F6 !important;
}

.MuiInput-underline, .MuiInput-underline.Mui-error::after, .MuiInput-underline::before, .MuiInput-underline::after {
  border-bottom: #496dba80 1px solid !important;
}

#logo {
  animation-duration: 2s;
  transform-origin: bottom;
  width: 150px;
}

.logo-bounce {
  animation-name: logo-bounce;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1)
}

.App-header {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  padding: 80px 0 20px 0;
}

.MuiRating-iconEmpty {
  color:#496CBA !important;
  opacity: .5;
}

.App-link {
  color: #BDC8E2;
}

#thankYouMsg {
  transition: 1s;
  opacity: 0;
  margin-top: 100px;
  position: absolute;
  top: 75%;
  text-align: center;
  padding: 0 10px;
  max-width: 90vw;
}

#thankYouMsg:hover {
  cursor: default;
}

@keyframes logo-bounce {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.07,.93)   translateY(0); }
  30%  { transform: scale(.95,1.05)   translateY(-50px); }
  50%  { transform: scale(1.02,.98) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-2px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}

@media screen and (min-width: 1000px) {
  .formTitle {
    font-size: 25px;
    margin-bottom: 5px;
    margin-top: 50px;
  }
  .formField {
    margin: 10px 0;
  }
}

@media screen and (max-width: 426px) {
  .App-header {
    padding-top: 75px;
  }
  .MuiTextField-root {
    width: 285px !important;
  }
}